
.navbar {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
}

.links {
    /* margin-right: 50px; */
    display: flex;
    align-items: end;
    margin-left: auto;
}

.links a {
    text-decoration: none;
    color: #D3E4CD;
    margin-left: 20px;
    font-size: 15px;
}

.links a:hover {
    color: #ffffff;
}

header b {
    color: white;
}