* {
  /* margin-top: 0;
  padding-top: 0; */
  /* box-sizing: border-box; */
}

@media (max-width: 600px) {
  #birdssection {
    grid-template-columns:repeat(4, 6rem);
    grid-template-rows: repeat(6, 6rem);
    align-content: center;
    justify-content: center;
    grid-gap: 0.5rem;
  }

  /* #birdmemobody{
    display: inline-flex;
  } */

  /* #block{
    padding-top: 120pt;
    display: inline-flex;
  } */

  }




.birdmemobody{
  height: 100vh;
  display: flex;
  /* text-align:center; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

section {
  display: grid;
  grid-template-columns:repeat(6, 9rem);
  grid-template-rows: repeat(4, 9rem);
  grid-gap: 1rem;
  perspective: 800px;
}

.card {
  position: relative;
  transform-style: preserve-3d;
  transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: rgba(0,0,0,0.2) 0px 5px 15px;
  /* transform: rotateY(180deg); */
}

.face, .back {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}

.back{
  /* background: blue; */
  background: url("../images/memo_birds/grey-bird.jpg");
  backface-visibility: hidden;
  background-size:100% 100%;
}

.toggleCard{
  transform: rotateY(180deg);
}

.face{
  transform: rotateY(-180deg);
}


/* // document */
body {
 padding: 3% 15%;
 /* background-color: #D3DEDC; */
}

/* @media only screen and (max-width: 950px) {
  body {
   padding: 3% 15%;
   background-color: #D3DEDC;
  }
} */

/* p { display: table; } */

#title {
  padding-bottom: 3%;
  background-color: #fff;
  color: #558776;
  /* font-weight: 600; */
  /* font-style: 'Comfortaa', cursive; */
  /* text-align: left; */
}

h1, h2, h5 {
font-family: 'Montserrat Alternates', sans-serif;
margin-bottom: 10px;
}

.ecocenterlink{
  color: #558776;
  text-decoration: none;
}

.ecocenterlink:hover{
  color: #D3E4CD;
  text-decoration: none;

}


@media (max-width: 1600px) {
  body {
   padding: 0% 0%;
   /* font-size: 2rem; */
  }
  /* h1{
    font-size: 3.5rem;
  }
  h2{
    font-size: 3rem;
  }
  h5{
    font-size:3rem;
  } */
  /* .nav-item{
    font-size:2.5rem;
  } */
  /* .btn{
    width:100%;
    font-size: 2rem; */
  }


  .card-title-tour{
    font-size: 15px;
  }
 

#ecocourse{
  /* padding: 3%; */
  /* background-color: #DDDDDD; */
}

.navbar-nav{
  font-weight: bold;
  /* color: #519259; */
  font-size: 1.2rem;
}

.nav.navbar-nav.ms-auto li a {
    color: #558776;
}

.nav.navbar-nav.ms-auto li a:hover {
    color: #D3E4CD;
}

.navbar-toggler.ms-auto{
  border-color: #558776;
}

 .menu-icon {
  color: #558776;
}

.navbar-toggler-icon.navbar-toggler.ms-auto {
  color: red;
}

.maintitle{
  text-align: left;
}

#content{
  padding-top: 30px;
}

.card{
  background-color: #DDDDDD;
}

.download-btn{
  margin-top: 10px;
  background-color: #558776;
  width:100%;
}

.course-btn{
  /* margin-top: 10px; */
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #558776;
}

.course-btn-hover{
  /* margin-top: 10px; */
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #D3E4CD;
}

.icon{
  margin-top: 20px;
  margin-right: 30px;
  color: #fff;
}

#footer{
  text-align: center;
}

.social-icon{
  margin: 20px 10px;
  color: black;
}

.text{
  padding: 2%;
}

.tab-pane{
  background-color: #fff;
  padding: 10px;
}

.nav-link{
  color: black;
}

.nav-link:hover{
  color: black;
}
