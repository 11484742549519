@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');



#header br {
    display: none;
  }

@media (max-width: 600px) {
    #navmain{
        height: 200px;
    }
    #header br {
      display: initial;
   
    }

    #header div{
        font-size: 0.5rem;
        margin-bottom:20px;
    }

    #navsecond .link{
        font-size: 10px;
    }

    #navsecond{
        justify-content: start;
    }

    #footer a{
        font-size: 10px;
    }

    
    #footer h5{
        font-size: 13px;
    }

    
    #footer p{
        font-size: 10px;
    }

    
  }

  #header{
    margin-top: 3%;
  }

.ecocenterlink {
    color: #558776;
    text-decoration: none;

    /* transition: all 500ms ease; */
}

.break-link {
    word-break: break-word;
    color: #D3E4CD;
}

.ecocenterlink:hover {
    color: #D3E4CD;
    text-decoration: none;
}

header {
    position: relative;
    margin: 0 10%;
    padding-bottom: 0;
    background-color: #558776;
}

header br {
    display: none;
  }

header .shop-cart-button {
    color: #D3E4CD;
    float: right;
    cursor: pointer;
    transition: color, transform 500ms ease;
}

header .shop-cart-button:hover,
header .shop-cart-button.active {
    color: #dc3d3d;
    transform: scale(1.5);
}

header .shop-cart {
    position: absolute;
    top: 30px;
    right: 0;
    width: 450px;
    /* background: #558776; */
    -webkit-box-shadow: 5px 7px 4px -5px rgba(0, 0, 0, 0.58);
    box-shadow: 5px 7px 4px -5px rgba(0, 0, 0, 0.58);
    z-index: 1000;
    padding: 20px;
    padding-bottom: 30px;
}

header .shop-cart .item {
    width: 100%;
    float: left;
    margin-bottom: 0;
}

.navbar {
    background-color: #558776;
}


.navbar2{
background-color: #558776;
/* background-color: #f8f9fa; */
box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
}

header .shop-cart .item img {
    width: 70px;
    float: left;
    margin-right: 20px;
}

header .shop-cart .item h2 {
    color: #D3E4CD;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 0;
}


header .shop-cart .item p {
    color: #D3E4CD;
    font-weight: 600;
    margin-top: 0;
}

header .shop-cart .empty h2 {
    color: #D3E4CD;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 0;
}

.cart-number{
color: red;
}

.cart-number-zero{
    color: #f2f2f2;
    }

.warning {  
        padding: 3% 10%;
    }

.block {
    padding: 3% 10%;
}

header .shop-cart .item .delete-icon {
    color: #ca5252;
    float: right;
    position: relative;
    top: -25px;
    cursor: pointer;
    transition: transform 500ms ease;
}

header .shop-cart .item .delete-icon:hover {
    color: #8f2525;
    transform: scale(1.5);
}

header .shop-cart .summa {
    float: left;
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    /* margin-bottom: 20px; */
    margin-top: 0;
}


.categories{
    /* padding: 0 15% 0 5%; */
    margin: 0 10%;
}

.categories div {
    display: inline-block;
    background-color: #f2f2f2;
    border-radius: 50px;
    padding: 10px 20px;
    margin-top: 20px;
    margin-right: 15px;
    cursor: pointer;
    border: 1px solid transparent;
    transition:  all 500ms ease;
}

.categories div:hover {
    border-color: silver;
    transform: scale(1.1);
}

.content .btn {
    width: 100%;
    background: #558776;
}

.content .itemImg,
.full-item img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    transition: transform 500ms ease;
    cursor: pointer;
}

.full-item {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:  rgba(0,0,0,0.8);
    z-index: 9999;
    overflow: auto;
}

.full-item div{
    width: 600px;
    position: relative;
    margin: 10% auto;
    padding: 40px 30px;
    background: #fff;
    border-radius: 9px;
}

.close-icon{
    color: #ca5252;
    height: 20px;
    width: 20px;
    /* float: right; */
    position: relative;
    float: right;
    top: 0;
    cursor: pointer;
    transition: transform 500ms ease;

}

.close-icon:hover {
    color: #8f2525;
    transform: scale(1.5);
}

.content .itemImg:hover {
    transform: scale(1.05);
}

footer {
    margin: 0 10%;
    background-color: #f8f9fa;
    text-align: center;
    margin-top: 100px;
    /* position: absolute;
    bottom: 0;
    width: 100%; */
}

.footer2{
    background-color: #f8f9fa;
}

footer .social {
    margin: 10px;
    color: #558776;
    transition: transform 500ms ease;
    cursor: pointer;
}

footer .social:hover {
    color: #558776;
    transform: scale(1.5);
}

.content {
    padding: 3% 10%;
}

h2{
    text-align: center;
}

.descr{
    text-align: center;
}

.imgApp{
    float: right; /* Выравнивание по правому краю  */ 
    margin: 0px 0 100px 20px; /* Отступы вокруг картинки */
    padding: 0px 30px 30px 30px;
}

.cartButton{
    text-align: center;
}

.header3{
    text-align: center;
}

.contacts2{
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: left;
    text-decoration: none;
    color:#558776;
}

.contacts{
    text-align: center;
}

.contacts2 a{
    text-align: left;
    text-decoration: none;
    color:#558776;
}

.contacts2-about{
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: left;
    text-decoration: none;
}

.contacts-about{
    text-align: center;
}

.contacts2-about a{
    text-align: left;
    text-decoration: none;
    color:black;
}

.greenlink{
    color:#558776;
}

.linkemail{
    margin-left: 10px;
}
