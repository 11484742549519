



.cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .cartItem {
    width: 100%;
    height: 250px;
    display: flex;
  
    align-items: center;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    margin: 30px;
  }
  
  .cartItem img {
    width: 200px;
    margin: 0px 15px 0px 15px;
    border-radius: 5px;
  }
  
  .cartItem .description {
    width: 100%;
    font-size: 30px;
  }
  
  .countHandler input {
    width: 40px;
    text-align: center;
    font-weight: bolder;
  }
  
  .btn-send {
    padding: 10px;
    /* width: 150px; */
    color: white;
    border: none;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    }